// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-jsx": () => import("/Users/yusuke/dev/uk5/gridio/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/yusuke/dev/uk5/gridio/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("/Users/yusuke/dev/uk5/gridio/src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-services-jsx": () => import("/Users/yusuke/dev/uk5/gridio/src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

exports.data = () => import("/Users/yusuke/dev/uk5/gridio/.cache/data.json")

